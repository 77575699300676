.card {
  margin-bottom: var(--io-spacing-6);
  background-color: var(--io-layer-1);
}

.textWithInfoTooltip {
  display: flex;
  align-items: center;
  gap: var(--io-spacing-1);
  flex-wrap: wrap;
}

.vaultName {
  font-weight: 400;
}
