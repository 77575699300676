@use 'src/app/variables' as *;

.button {
  --button-height: toRem(36);

  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 toRem(12);
  height: var(--button-height);

  border: 1px solid;
  border-radius: 4px;

  cursor: pointer;

  font-weight: 500;

  transition: transform 350ms ease;
}
.button:not(.link)[disabled] {
  pointer-events: none;
  background: var(--io-bg-disabled);
  border-color: var(--io-border-disabled);
  color: var(--io-text-disabled);
}
.button.link[disabled] {
  pointer-events: none;
  color: var(--io-text-disabled);
}

.button:active {
  transform: scale(0.95);
}

.iconBefore,
.iconAfter {
  display: inline-block;
  flex-shrink: 0;
  line-height: 1;
  width: toRem(16);
  height: toRem(16);

  & > svg {
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
    max-width: 100%;
    max-height: 100%;
    vertical-align: bottom;

    color: currentColor;
  }
}

.iconBefore {
  margin-right: var(--io-spacing-2);
}
.iconAfter {
  margin-left: var(--io-spacing-2);
}

.content {
  display: inline-block;
  flex-shrink: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/**
 * Sizing
 */

.large {
  --button-height: #{toRem(44)};
}
.medium {
  --button-height: #{toRem(36)};
}
.small {
  --button-height: #{toRem(30)};
}
.fullWidth {
  width: 100%;
}

/**
 * Shape
 */
.pill {
  border-radius: calc(var(--button-height) / 2);
}

/**
 * Variants
 */
.unstyled {
  /* Placeholder for buttons that will not have a default style */
}

.dark {
  background: var(--io-button-dark-background);
  border-color: var(--io-button-dark-background);
  color: var(--io-button-dark-text);

  &:hover,
  &:focus-visible {
    background: var(--io-button-dark-hover);
    border-color: var(--io-button-dark-hover);
  }
  &:active {
    background: var(--io-button-dark-active);
    border-color: var(--io-button-dark-active);
  }
}

.light {
  background: var(--io-button-light-background);
  border-color: var(--io-button-light-border);
  color: var(--io-button-light-text);

  &:hover,
  &:focus-visible {
    background: var(--io-button-light-hover);
    border-color: var(--io-button-light-border-hover);
  }
  &:active {
    background: var(--io-button-light-active);
    border-color: var(--io-button-light-border-hover);
  }
}

.primary {
  background: var(--io-primary);
  border-color: var(--io-primary);
  color: var(--io-button-dark-text);

  &:hover,
  &:focus-visible {
    background: var(--io-primary-hover);
    border-color: var(--io-primary-hover);
  }
  &:active {
    background: var(--io-primary-active);
    border-color: var(--io-primary-active);
  }
}

.danger {
  background: var(--io-button-danger-background);
  border-color: var(--io-button-danger-background);
  color: var(--io-button-danger-text);

  &:hover,
  &:focus-visible {
    background: var(--io-button-danger-hover);
    border-color: var(--io-button-danger-hover);
  }
  &:active {
    background: var(--io-button-danger-active);
    border-color: var(--io-button-danger-active);
  }
}

.dangerLight {
  background: var(--io-button-light-background);
  border-color: var(--io-button-light-border);
  color: var(--io-button-danger-light-text);

  &:hover,
  &:focus-visible {
    background: var(--io-button-light-hover);
    border-color: var(--io-button-light-border-hover);
  }
  &:active {
    background: var(--io-button-light-active);
    border-color: var(--io-button-light-border-hover);
  }
}

.link {
  color: var(--io-text);
  font-weight: 500;
  background: transparent;
  border-color: transparent;
  line-height: var(--io-spacing-4);

  &:hover,
  &:focus-visible,
  &:active {
    background-color: var(--io-button-light-hover);
  }
}
